import * as html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const a4Ratio = 297 / 210;
const scaleFactor = 2;

const roundingErrorMargin = 1;

// Downloads the element as a PDF file.
// onCloneFunction will be run against the cloned DOM before generating the canvas.
export function downloadAsPDF(element: Element, filename: string, oncloneFunction = (clonedDoc: Document) => {
}): void {
    html2canvas(element, {
        scale: scaleFactor,
        logging: false,
        onclone: function (clonedDoc: Document) {
            oncloneFunction(clonedDoc);
        }
    }).then((canvas: HTMLCanvasElement) => {
        const data = canvas.toDataURL("image/svg+xml");

        const docDefinition = {
            pageSize: {
                width: canvas.width - roundingErrorMargin,
                height: canvas.width * a4Ratio - roundingErrorMargin
            },
            pageMargins: [0, 0],
            content: [
                {
                    image: data,
                    width: canvas.width
                }]
        };
        pdfMake.createPdf(docDefinition).download(filename);
    });
}
