import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { Fejl } from "../../models/fejl.model";
import { UserInfo } from "../../models/user-info.model";
import { ConfigService } from "../../services/config/config.service";
import { DatePickerLocaleService } from "../../services/date-picker-locale/date-picker-locale.service";
import { HttpService } from "../../services/http/http.service";
import { RedirectService } from "../../services/redirect/redirect.service";
import { TranslationService } from "../../services/translate/translation.service";
import {
    clearUserInfo,
    getIndberetter,
    getIndberetterCompanyName,
    getPligtig,
    getPligtigCompanyName,
    getUsername,
    indberetterAndPligtigAreTheSame,
    setIndberetter,
    setIndberetterCompanyName,
    setPligtig,
    setPligtigCompanyName,
    setTextKeysVisible,
    setUsername
} from "../../utils/session-storage-utils";
import { isDefined } from "../../utils/text-utils";
import { getUrlParam, goToPortal, pathsWhereDataCanBeLost } from "../../utils/url-utils";

@Component({
    selector: "skat-cbc",
    templateUrl: "./router.component.html"
})

export class RouterComponent implements OnInit, OnDestroy {
    public focusOnLinkOnEnterMethod: Function;
    currentUrl: string;

    subscriptionToConfigSuccess: Subscription;
    subscriptionToConfigFejl: Subscription;
    subscriptionToAccessDenied: Subscription;
    subscriptionToUnauthorized: Subscription;

    confirmText: string;

    constructor(private configService: ConfigService,
                private router: Router,
                private httpService: HttpService,
                private translationService: TranslationService,
                private redirectService: RedirectService,
                private datePickerLocaleService: DatePickerLocaleService) {
    }

    ngOnInit() {
        this.translationService.environmentAwareTranslate('GENERELT.ROUTING.TIL_MENU_CONFIRM').subscribe((res: string) => {
            this.confirmText = res;
        });

        this.focusOnLinkOnEnterMethod = this.focusOnLinkOnEnter.bind(this);
        this.subscribeToConfigChanges();
        this.subscribeToUnauthorized();
        this.detectNyConfig();
    }

    ngOnDestroy() {
        this.subscriptionToConfigSuccess.unsubscribe();
        this.subscriptionToConfigFejl.unsubscribe();
        this.subscriptionToAccessDenied.unsubscribe();
        this.subscriptionToUnauthorized.unsubscribe();
    }

    subscribeToConfigChanges() {
        this.subscriptionToConfigSuccess = this.configService.loginInfo.subscribe(
            (json: UserInfo) => {
                if (this.confirmText == undefined) { // Texts not available
                    this.router.navigate(["/unavailable"]);
                }
                else {
                    setPligtig(json.pligtig);
                    setPligtigCompanyName(json.pligtigCompanyName);
                    setIndberetter(json.indberetter);
                    setIndberetterCompanyName(json.indberetterCompanyName);
                    setUsername(json.username);
                    this.datePickerLocaleService.setLanguageCode(json.languageCode);
                }
            }
        );

        this.subscriptionToConfigFejl = this.configService.loginInfoFejl.subscribe(
            (fejl: Fejl) => {
                if (this.confirmText == undefined) { // Texts not available
                    this.router.navigate(["/unavailable"]);
                }
                else {
                    const besked = fejl.text();
                    if (getPligtig() === null && this.currentUrl !== "/fejlside") {
                        this.router.navigate(["/fejlside", {
                            besked: besked
                        }]);
                    }
                }
            }
        );

        this.subscriptionToAccessDenied = this.configService.loginInfoAccessDenied.subscribe(
            (statusCode: number) => {
                if (statusCode === 403) {
                    this.redirectService.redirectToNoAccess();
                }
            }
        );
    }

    subscribeToUnauthorized() {
        this.subscriptionToUnauthorized = this.httpService.unauthorized.subscribe(
            (error: HttpErrorResponse) => {
                clearUserInfo();
                this.redirectService.redirectToLogin(this.currentUrl);
            }
        );
    }

    detectNyConfig() {
        this.router.events.filter(event => event instanceof NavigationStart)
            .subscribe(
                (event: NavigationStart) => {
                    if (event.url.indexOf("/fejlside") === -1 && event.url.indexOf("/unavailable") === -1) {
                        this.currentUrl = event.url;
                        const tekster = this.teksterSkalVises();
                        if (["true", "false"].indexOf(tekster) >= 0) {
                            setTextKeysVisible(tekster);
                        }

                        this.configService.hentConfig(this.currentUrl);
                    }
                }
            );
    }

    teksterSkalVises(): string {
        return getUrlParam("tekster");
    }

    get pligtig(): string {
        return getPligtig();
    }

    get indberetter(): string {
        return getIndberetter();
    }

    get pligtigCompanyName(): string {
        const name = getPligtigCompanyName();
        if (isDefined(name)) {
            return name;
        }
        return "";
    }

    get indberetterCompanyName(): string {
        const name = getIndberetterCompanyName();
        if (isDefined(name)) {
            return name + " |";
        }
        return "";
    }

    get username(): string {
        const name = getUsername();
        if (isDefined(name)) {
            return name + " | ";
        }
        return "";
    }

    get indberetterAndPligtigAreTheSame(): boolean {
        return indberetterAndPligtigAreTheSame();
    }

    get userMightLooseData(): boolean {
        const url = window.location.href;
        if (url.indexOf("/#/") !== -1) {
            const localPath = url.split("/#/")[1];
            if (pathsWhereDataCanBeLost.indexOf(localPath) >= 0) {
                return true;
            }
        }
        return false;
    }

    goToPortal() {
        let confirmed = true;
        if (this.userMightLooseData) {
            confirmed = window.confirm(this.confirmText);
        }
        if (confirmed) {
            goToPortal(this.router);
        }
    }

    /*
     Functionality so the user by tapping can skip the navigation
     */
    public focusOnLinkOnEnter() {
        jQuery("#skts-skip-link-target-indhold").focus();
    }

    shouldShowPligtig(): boolean {
        return this.pligtig && !this.indberetterAndPligtigAreTheSame;
    }
}
