import { Injectable } from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { FiloverfoerselModel } from '../../../filoverfoersel/models/filoverfoersel.model';
import { Fejl } from "../../models/fejl.model";
import { mapConsolidatedJsonToReportModel } from "../../utils/consolidated-report-mapper";
import { nyStandardFejl } from '../../utils/fejl-utils';
import { mapValidationErrorList } from '../../utils/mapping-utils';
import { parseXMLFileToReportModel } from "../../utils/report-mapper";
import {
    genererUrlTilIndberetningsXML,
    genererUrlTilKonsolidering,
    genererUrlTilOversigt
} from "../../utils/url-utils";
import { HttpService } from "../http/http.service";
import { IndberetningResultModel } from "./indberetning-result.model";
import { KonsolideretIndberetningResultModel } from "./konsolideret-indberetning-result.model";

@Injectable()
export class IndberetningService {

    indberetningHentet: Subject<IndberetningResultModel> = new Subject<IndberetningResultModel>();
    indberetningerHentet: Subject<Array<FiloverfoerselModel>> = new Subject<Array<FiloverfoerselModel>>();
    indberetningerHentetFejl: Subject<Fejl> = new Subject<Fejl>();
    konsolideretIndberetningHentet: Subject<KonsolideretIndberetningResultModel> = new Subject<KonsolideretIndberetningResultModel>();
    konsolideretIndberetningHentetFejl: Subject<Fejl> = new Subject<Fejl>();

    constructor(private http: HttpService) {
    }

    hentIndberetningsXML(id: number) {
        this.http.getText(genererUrlTilIndberetningsXML(id)).subscribe((xml: string) => {
            const filBlob = new Blob([xml], {type: 'text/plain'});
            parseXMLFileToReportModel(filBlob).then(model => {
                this.indberetningHentet.next(new IndberetningResultModel(id, model));
            });
        });
    }

    async hentIndberetningsBlob(id: number) : Promise<Blob> {
        try {
            const response = await this.http.getText(genererUrlTilIndberetningsXML(id)).toPromise();
            return new Blob([response], {type: 'text/plain'});
        }
        catch(err) {
            this.indberetningerHentetFejl.next(nyStandardFejl());
        }
    }

    hentKonsolideretIndberetning(reportingPeriod: string) {
        this.http.get<any>(genererUrlTilKonsolidering(reportingPeriod)).subscribe(response => {
            const reportModel = mapConsolidatedJsonToReportModel(response);
            this.konsolideretIndberetningHentet.next(new KonsolideretIndberetningResultModel(reportingPeriod, reportModel));
        }, (error: any) => {
            this.konsolideretIndberetningHentetFejl.next(nyStandardFejl());
        });
    }

    hentIndberetninger() {
        this.http.get<any>(genererUrlTilOversigt()).subscribe(response => {
            const models: FiloverfoerselModel[] = [];

            if (response.included === undefined) {
                this.indberetningerHentet.next(models);
            } else {
                // loop response/list of indberetninger
                for (const i in response.included) {
                    const int = parseInt(i) + 1;
                    const entry = response.included[i];
                    const model = new FiloverfoerselModel();
                    model.oprettelsesid = entry.id;
                    model.messageRefId = entry.attributes.messageRefId;
                    model.status = entry.attributes.status;
                    model.reportingPeriod = entry.attributes.regnskabsPeriodeSlut;
                    model.date = entry.attributes.indberettetTid;
                    model.filnavn = entry.attributes.indsendtFilnavn;
                    if (entry.attributes.advisFejl != undefined || entry.attributes.xmlFejl != undefined || entry.attributes.indholdsFejl != undefined) {
                        model.valideringsFejl = mapValidationErrorList(entry.attributes);
                    }
                    model.indberetningsType = entry.attributes.indberetningsType;
                    models.push(model);
                    if (int === response.included.length) {
                        // if last indberetning in loop, trigger subject event and pass models
                        this.indberetningerHentet.next(models);
                    }
                }
            }
        }, (error: any) => {
            this.indberetningerHentetFejl.next(nyStandardFejl());
        });
    }
}
