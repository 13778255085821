import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { TilkendegivelseKvitteringModel } from "../../../../tilkendegivelser/models/tilkendegivelse-kvittering.model";
import { zuluDateToDkNoDashes } from "../../../utils/date-utils";
import { a4Ratio, downloadAsPDF } from "../../../utils/pdf-util";
import { indberetterAndPligtigAreTheSame } from "../../../utils/session-storage-utils";

@Component({
    selector: "skat-tilkendegivelse-view",
    templateUrl: "./tilkendegivelse-view.component.html",
    styleUrls: ['./tilkendegivelse-view.component.css']
})
export class TilkendegivelseViewComponent {

    @Input() model: TilkendegivelseKvitteringModel;

    // True if this tilkendegivelse has not yet been corrected
    @Input() correctLinkEnabled: boolean = true;
    @Input() pdfLinkEnabled: boolean = true;

    constructor(private router: Router) {
    }

    downloadAsPDF() {
        const targetId = "printable-" + this.model.id;
        const element = document.getElementById(targetId);
        const filename = `Land for land-Tilkendegivelse-${zuluDateToDkNoDashes(this.model.oprettelsesTid)}.pdf`;

        downloadAsPDF(element, filename, (doc) => this.pdfDomPreprocess(this.model.id, doc));
    }

    pdfDomPreprocess(id: number, clonedDoc: Document) {
        const pdfElement = clonedDoc.getElementById('printable-' + id);
        pdfElement.classList.add("pdf-visible");

        const footerMarginTop = calculateFooterMarginTop(id, clonedDoc);

        clonedDoc.getElementById('pdf-footer-' + id).style.marginTop = `${footerMarginTop}px`;
        clonedDoc.getElementById('pdf-button-' + id).style.display = 'none';
        clonedDoc.getElementById('tilkendegivelse-' + id).classList.add("well");
    }

    goToRetTilkendegivelse() {
        this.router.navigate(['/tilkendegiv'], {queryParams: {corrects: this.model.id}});
    }

    indberetterAndPligtigAreTheSame(): boolean {
        return indberetterAndPligtigAreTheSame();
    }

}

function calculateFooterMarginTop(id: number, clonedDoc: Document): number {
    const header = clonedDoc.getElementById('pdf-header-' + id);
    const pageWidth = header.offsetWidth;
    const pageHeight = pageWidth * a4Ratio;

    const headerHeight = header.offsetHeight;
    const bodyHeight = clonedDoc.getElementById('pdf-body-' + id).offsetHeight;
    const footerHeight = clonedDoc.getElementById('pdf-footer-' + id).offsetHeight;

    const headerMargin = 40 + 10;

    return pageHeight - bodyHeight - headerHeight - headerMargin - footerHeight;
}
