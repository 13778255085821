import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from "rxjs/Subscription";
import { uploadType } from "../../../shared/enums/uploadtype.enum";
import { Fejl } from "../../../shared/models/fejl.model";
import { Valideringsfejl } from "../../../shared/models/valideringsfejl.model";
import { RedirectService } from "../../../shared/services/redirect/redirect.service";
import { generateErrorItems } from "../../../shared/utils/fejl-utils";
import { mapValidationErrorList } from '../../../shared/utils/mapping-utils';
import { genererUrlTilFiluploadIndberetning, genererUrlTilFiluploadValider } from "../../../shared/utils/url-utils";
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadFactory } from "./filupload.factory";

@Injectable()
export class FiluploadService {

    private method: string = 'POST';
    private uploadtype: uploadType;
    private redirectTargetUrl: string;
    public isUploading: boolean;
    public errors: Array<Fejl>;
    model: FiloverfoerselModel;

    onErrorSubscription: Subscription;
    onSuccessSubscription: Subscription;

    @Output() progressEvent = new EventEmitter();
    uploadCompleted: Subject<FiloverfoerselModel> = new Subject<FiloverfoerselModel>();
    uploadCompletedWithError: Subject<Array<Fejl>> = new Subject<Array<Fejl>>();
    validationErrorEvent: Subject<Valideringsfejl> = new Subject<Valideringsfejl>();

    constructor(private filoverfoerselModel: FiloverfoerselModel,
                private filuploadFactory: FiluploadFactory,
                private redirectService: RedirectService) {
        this.model = filoverfoerselModel;
        this.onErrorSubscription = this.filuploadFactory.onError.subscribe((error: [any, number]) => error ? this.handleError(error) : void(0));
        this.onSuccessSubscription = this.filuploadFactory.onSuccess.subscribe((success: [any, number]) => success ? this.onSuccessItem(success[0], success[1]) : void(0));
    }

    ngOnDestroy() {
        this.onErrorSubscription.unsubscribe();
        this.onSuccessSubscription.unsubscribe();
    }

    public uploadFil(type: uploadType, redirectTargetUrl: string): void {
        if (this.isUploading) {
            return;
        }
        this.init();
        this.uploadtype = type;
        this.isUploading = true;
        this.redirectTargetUrl = redirectTargetUrl;
        this.xhrTransport();
    }

    private init() {
        this.isUploading = false;
    }

    private getUploadUrl(): string {
        if (this.uploadtype === uploadType.indberet) {
            return genererUrlTilFiluploadIndberetning();
        } else {
            return genererUrlTilFiluploadValider();
        }
    }

    public onSuccessItem(response: any, status: number) {
        this.isUploading = false;
        this.onSuccess(response, status);
    }

    private handleError(error: [any, number]) {
        const status = error[1];
        if (status === 401) {
            this.redirectService.redirectToLogin(this.redirectTargetUrl);
        } else {
            this.onErrorItem(error[0], status);
        }
    }

    public onErrorItem(response: any, status: number) {
        this.isUploading = false;
        this.errors = generateErrorItems(response, status);
        this.uploadCompletedWithError.next(this.errors);
    }

    public onSuccess(response: any, status: number) {
        const json = JSON.parse(response);
        this.model.oprettelsesid = json.data.id;
        this.model.status = json.data.attributes.status;
        if(json.data.attributes.indberettetTid !== undefined && json.data.attributes.regnskabsPeriodeSlut !== undefined && json.data.attributes.messageRefId !== undefined) {
            this.model.date = json.data.attributes.indberettetTid;
            this.model.reportingPeriod = json.data.attributes.regnskabsPeriodeSlut;
            this.model.messageRefId = json.data.attributes.messageRefId;
        }

        if (response !== "" && json.data.attributes.status !== undefined) {
            // if status not GODKENDT and validationerrors has been set
            if (json.data.attributes.status !== 'GODKENDT' && (json.data.attributes.indholdsFejl !== undefined || json.data.attributes.xmlFejl !== undefined)) {
                this.validationErrorEvent.next(mapValidationErrorList(json.data.attributes));
            } else if(json.data.attributes.status === 'GODKENDT'){ // if status GODKENDT

                // if advis is set, map it to Valideringsfejl tilkendegivelseModel
                if(json.data.attributes.advisFejl !== undefined){
                    this.model.valideringsFejl = mapValidationErrorList(json.data.attributes);
                }
                this.uploadCompleted.next(this.model);
            }
        }
    }

    private xhrTransport() {
        const item: File = this.filoverfoerselModel.fil;

        const xhr = this.filuploadFactory.createXMLHttpRequest(this.progressEvent);
        if (typeof item.size !== 'number') {
            throw new TypeError('The file specified is no longer valid');
        }
        xhr.open(this.method, this.getUploadUrl(), true);
        xhr.setRequestHeader("Content-Type", `application/xml; schema="urn:oecd:ties:cbc:v2"`);
        xhr.setRequestHeader("Content-Disposition", 'attachment; filename="'+item.name+'"');
        xhr.send(item);
    }

}
