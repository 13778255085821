export const environment = {
    production: true,
    apiUrl: 'https://sso-api.tsecbcindberet.tst.skat.dk',
    portalUrl: '', // <-- Skal være tom string i lokal configuration
    logoutUrl: '', // <-- ... og det samme gælder for denne

    // External login page. Error page is used in development since there is no dev login page.
    loginUrl: 'https://skttdcs63-por.skat.dk/dcs-atn-gateway/login/tsklogin',
    noAccessUrl: 'https://skttdcs63-por.skat.dk/dcs-atn-gateway/error/noacess'
};
