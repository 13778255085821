import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';
import { FiloverfoerselModel } from '../../../filoverfoersel/models/filoverfoersel.model';
import { ErrorService } from '../../../shared/services/error/error.service';
import { IndberetningService } from '../../../shared/services/indberetning/indberetning.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { nyStandardFejl, standardOverskrift } from '../../../shared/utils/fejl-utils';
import { compareTimestamps, formatSimpleDate } from '../../../shared/utils/text-utils';
import { ConsolidatedIndberetningModel } from "../../models/consolidated-indberetning.model";

@Component({
    selector: 'skat-oversigt',
    templateUrl: './oversigt.component.html',
    styleUrls: ['./oversigt.component.css']
})
export class OversigtComponent implements OnInit, OnDestroy, AfterViewInit{

    consolidatedIndberetninger: Map<string, ConsolidatedIndberetningModel> = new Map<string, ConsolidatedIndberetningModel>();
    indberetninger: Map<string, FiloverfoerselModel[]> = new Map<string, FiloverfoerselModel[]>();
    reportingPeriods: Array<string> = [];
    errorsOverskrift: string;
    indberetningerHentetSubscription: Subscription;
    indberetningerHentetFejlSubscription: Subscription;

    readonly showDetailsView: boolean = false;

    constructor(private translationService:TranslationService,
                private service:IndberetningService,
                public errors: ErrorService,
                private titleService: Title) {
    }

    ngOnInit(){
        this.errors.reset();
        this.subscribeToIndberetningerHentet();
        this.subscribeToErrors();
        this.service.hentIndberetninger();

        this.translationService.environmentAwareTranslate('OVERSIGT.SPINNER').subscribe((res: string) => {
            page.showLoader(res);
        });
    }

    ngAfterViewInit(){
        this.translationService.environmentAwareTranslate('INDBERETNINGER.OVERSKRIFT').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
    }

    subscribeToIndberetningerHentet(){
        const newestDates: Map<string, string> = new Map<string, string>();

        this.indberetningerHentetSubscription = this.service.indberetningerHentet.subscribe((models: Array<FiloverfoerselModel>) => {
            for (const model of models) {
                if(model.status === 'GODKENDT') {
                    const reportingPeriod = formatSimpleDate(model.reportingPeriod);
                    let indberetninger = this.indberetninger.get(reportingPeriod);
                    if(indberetninger == undefined){
                        indberetninger = [];
                    }
                    indberetninger.push(model);

                    this.indberetninger.set(reportingPeriod, indberetninger);
                    if(!this.reportingPeriods.includes(reportingPeriod)){
                        this.reportingPeriods.push(reportingPeriod);
                    }
                    const newestDate = newestDates.get(reportingPeriod);
                    if(!newestDate || compareTimestamps(model.date, newestDate) > 0) {
                        newestDates.set(reportingPeriod, model.date);
                    }
                }
            }
            for (const reportingPeriod of this.reportingPeriods) {
                const consolidatedIndberetning = new ConsolidatedIndberetningModel();
                consolidatedIndberetning.reportingPeriod = reportingPeriod;
                consolidatedIndberetning.date = newestDates.get(reportingPeriod);
                this.consolidatedIndberetninger.set(reportingPeriod, consolidatedIndberetning);
            }

            page.hideLoader();
        });
    }

    subscribeToErrors(){
        this.indberetningerHentetFejlSubscription = this.service.indberetningerHentetFejl.subscribe(fejl => {
            this.errors.tilfoejFejl([nyStandardFejl()]);
            this.errorsOverskrift = standardOverskrift();
            page.hideLoader();
        });
    }

    ngOnDestroy(){
        this.indberetningerHentetSubscription.unsubscribe();
        this.indberetningerHentetFejlSubscription.unsubscribe();
    }

}
