import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Fejl } from "../../models/fejl.model";
import { UserInfo } from "../../models/user-info.model";
import { nyStandardFejl } from "../../utils/fejl-utils";
import { getPligtig } from "../../utils/session-storage-utils";
import { configUrl } from "../../utils/url-utils";
import { HttpService } from "../http/http.service";

@Injectable()
export class ConfigService {

    loginInfo: Subject<UserInfo> = new Subject<UserInfo>();
    loginInfoFejl: Subject<Fejl> = new Subject<Fejl>();
    loginInfoAccessDenied: Subject<number> = new Subject<number>();

    constructor(private http: HttpService) {
    }

    hentConfig(currentUrl: string) {
        this.http.get<UserInfo>(configUrl(), {frontendPath: currentUrl}).subscribe((json: UserInfo) => {
                this.loginInfo.next(json);
            }, (error: HttpErrorResponse) => {
                if (error.status === 403) {
                    this.loginInfoAccessDenied.next(error.status);
                } else if (error.status !== 401 && getPligtig() == null) { // a dedicated 401 event is emitted from HttpService
                    this.loginInfoFejl.next(nyStandardFejl());
                }
            }
        );
    }
}
