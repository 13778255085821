import { Valideringsfejl } from '../../shared/models/valideringsfejl.model';

/**
 * Represents (the metadata of) an indberetning, that is without its business data.
 */
export class FiloverfoerselModel {
    fil:File;
    filnavn:string;
    reportingPeriod: string;
    date: string;
    oprettelsesid:number;
    status:string;
    valideringsFejl: Valideringsfejl;
    errorsOverskrift: string;
    messageRefId: string;
    indberetningsType: string;

    constructor() {
        this.init();
    }

    init() {
        this.fil = null;
        this.filnavn = null;
        this.oprettelsesid = null;
        this.status = null;
        this.valideringsFejl = null;
        this.errorsOverskrift = null;
        this.messageRefId = null;
        this.indberetningsType = null;
    }
}
