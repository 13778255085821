import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { ConsolidatedIndberetningModel } from "../../../../indberetninger/models/consolidated-indberetning.model";
import { Fejl } from "../../../models/fejl.model";
import { ReportModel } from '../../../models/report.model';
import { ErrorService } from '../../../services/error/error.service';
import { IndberetningService } from '../../../services/indberetning/indberetning.service';
import { KonsolideretIndberetningResultModel } from "../../../services/indberetning/konsolideret-indberetning-result.model";
import { TranslationService } from "../../../services/translate/translation.service";
import { nyStandardFejl, standardOverskrift } from '../../../utils/fejl-utils';
import { formatSimpleDate, formatZuluDateTime, reverseFormatSimpleDate } from '../../../utils/text-utils';
import { ReportComponent } from '../report/report.component';

@Component({
    selector: "skat-samlet-indberetning-view",
    templateUrl: "./consolidated-indberetning.component.html",
    styleUrls: ['./../indberetning/indberetning.component.css']
})
export class ConsolidatedIndberetningComponent implements OnInit, OnDestroy {

    @ViewChild(ReportComponent) reportComponent: ReportComponent;
    @Input() model: ConsolidatedIndberetningModel;

    private subscriptionToKonsolideretIndberetningHentet: Subscription;
    private subscriptionToKonsolideretIndberetningHentetFejl: Subscription;
    consolidatedReports: Map<string, ReportModel> = new Map<string, ReportModel>();
    errorsOverskrift: string;

    // Print-related variables
    printReportingPeriod: string = null;
    containersSelector: string = '.well.indberetning';

    spinnerText: string = '';

    constructor(private indberetningService: IndberetningService,
                private translationService: TranslationService,
                public errors: ErrorService) {
    }

    formatDate(date: string): string {
        return formatZuluDateTime(date);
    }

    ngOnInit() {
        this.errors.reset();
        this.subscribeToKonsolideretIndberetningHentet();
        this.subscribeToErrors();
        this.translationService.environmentAwareTranslate('OVERSIGT.SAMLET.SPINNER').subscribe((text: string) => {
            this.spinnerText = text;
        });
    }

    subscribeToKonsolideretIndberetningHentet() {
        this.subscriptionToKonsolideretIndberetningHentet = this.indberetningService.konsolideretIndberetningHentet.subscribe((result: KonsolideretIndberetningResultModel) => {
            page.hideLoader();
            // Transform the reportingPeriod to simple date format before saving it.
            this.consolidatedReports.set(formatSimpleDate(result.reportingPeriod), result.reportModel);
            if (this.printReportingPeriod) {
                // A print request was sent, print now
                this.doPrint();
            }
        });
    }

    subscribeToErrors() {
            this.subscriptionToKonsolideretIndberetningHentetFejl = this.indberetningService.konsolideretIndberetningHentetFejl.subscribe(fejl => {
            if ( !this.errors.alleFejl().some(fejl => fejl.fejlBeskrivelse === "GENERELT.FEJL.STANDARD_FEJLBESKED" )) {
                this.errors.tilfoejFejl([nyStandardFejl()]);
            }
            this.errorsOverskrift = standardOverskrift();
            page.hideLoader();
        });
    }

    ngOnDestroy() {
        this.subscriptionToKonsolideretIndberetningHentet.unsubscribe();
        this.subscriptionToKonsolideretIndberetningHentetFejl.unsubscribe();
        this.consolidatedReports = null;
    }

    hasData(reportingPeriod: string): boolean {
        const reportModel = this.consolidatedReports.get(reportingPeriod);
        return reportModel && reportModel.hasData();
    }

    /**
     * Request a print of data for the reporting period: If it already exists, print right away. Otherwise fetch the data.
     */
    requestPrint(reportingPeriod: string) {
        this.printReportingPeriod = reportingPeriod;
        if (this.fetchConsolidatedData(reportingPeriod)) {
            this.doPrint();
        }
    }

    /**
     * Print data for the selected reporting period.
     */
    private doPrint() {
        // Define elements to print
        this.reportComponent.print = true;

        const indberetningIdSelector = '#samlet-indberetning' + this.printReportingPeriod;
        this.printReportingPeriod = null;

        jQuery(this.containersSelector).addClass('noprint');
        jQuery(indberetningIdSelector).removeClass('noprint');
        jQuery(indberetningIdSelector + ' .hidden').addClass('printAll');

        setTimeout(() => {
            window.print();

            // Restore elements to print
            this.reportComponent.print = false;
            jQuery(this.containersSelector).removeClass('noprint');
            jQuery(indberetningIdSelector + ' .hidden').removeClass('printAll');
        }, 500);
    }

    /**
     * Fetch data for the reporting period if it has not already been fetched.
     * @returns a boolean indicating whether the data already exists.
     */
    fetchConsolidatedData(reportingPeriod: string): boolean {
        if (!this.consolidatedReports.get(reportingPeriod)) {
            page.showLoader(this.spinnerText);
            // Transform the reportingPeriod to ISO format before calling the service.
            this.indberetningService.hentKonsolideretIndberetning(reverseFormatSimpleDate(reportingPeriod));
            return false;
        }
        return true;
    }
}
