import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { TranslationService } from "../services/translate/translation.service";
import { isDefined } from "../utils/text-utils";

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslationPipe implements PipeTransform {

    constructor(private translationService: TranslationService) {
    }

    transform(query: string, ...args: any[]): any {
        if (!query || query.length === 0) {
            return Observable.of(query);
        }

        let interpolateParams: any;
        if (isDefined(args[0]) && args.length) {
            if (typeof args[0] === 'string' && args[0].length) {
                // we accept objects written in the template such as {n:1}, {'n':1}, {n:'v'}
                // which is why we might need to change it to real JSON objects such as {"n":1} or {"n":"v"}
                const validArgs: string = args[0]
                    .replace(/(\')?([a-zA-Z0-9_]+)(\')?(\s)?:/g, '"$2":')
                    .replace(/:(\s)?(\')(.*?)(\')/g, ':"$3"');
                try {
                    interpolateParams = JSON.parse(validArgs);
                } catch (e) {
                    throw new SyntaxError(`Wrong parameter in TranslatePipe. Expected a valid Object, received: ${args[0]}`);
                }
            } else if (typeof args[0] === 'object' && !Array.isArray(args[0])) {
                interpolateParams = args[0];
            }
        }

        return this.translationService.environmentAwareTranslate(query, interpolateParams);
    }
}
